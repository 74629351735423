<div class="card m-3 shadow-lg bg-white rounded">
	<div class="card-header p-3">
		<fa-icon [icon]="info" class="text-secondary"></fa-icon>&nbsp;Info
		<div class="col small">
			To access the Maintenance menu on the kiosk, scan the QR code from the kiosk screen.
		</div>
	</div>
</div>

<!-- <div *ngIf="kiosk" class="card m-3 shadow-lg bg-white rounded"> -->
<div class="card m-3 shadow-lg bg-white rounded">
	<div class="row m-3" *ngIf="qrValue !== undefined">
		<div class="col text-center">
			<button type="button" class="btn btn-secondary" (click)="scanAgain()">Scan</button>
		</div>
	</div>
	<div class="row m-3" *ngIf="qrValue === undefined">
		<div class="col">
			<zxing-scanner #scanner (scanSuccess)="onCodeResult($event)"></zxing-scanner>					
		</div>
	</div>
	<div class="row m-3">
		<div class="col" *ngIf="qrValue">
			<div class="card">
				<div class="card-header text-success">QR Data</div>
				<div class="card-body"><small>{{qrValue}}</small></div>
			</div>
		</div>
	</div>
	<div class="row m-3" *ngIf="serverError">
		<div class="col">
			<div class="card">
				<div class="card-header text-danger">Access to Maintenance menu denied</div>
				<div class="card-body">{{serverError}}</div>
			</div>
		</div>		
	</div>
	<div class="row m-3" *ngIf="qrValue && !serverError">
		<div class="col">
			<div class="card">
				<div class="card-header text-success">Request to access Maintenance menu has been accepted.</div>
				<div class="card-body">Please wait for the Kiosk to refresh and grant access</div>
			</div>
		</div>
	</div>
</div>