import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { KioskDto, KioskLocationDto } from "../../dto";
import { HttpKioskService } from "../../http-kiosk.service";
import { faCheck, faHourglassStart, faLock, faUnlock, faBan, faEdit, faSearch, faTimes, faQrcode, faInfo, faInfoCircle, faArrowsSplitUpAndLeft, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilService } from "../../util.service";
import { KioskActionService } from "../../kiosk-action.service";
import { Subject, Subscription } from "rxjs";
import { HttpLocationService } from "../../http-location.service";
import { catchError, debounceTime } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";
import { HttpTechnicianService } from "../../http-technician.service";


@Component({
	selector: 'app-technician-maintenance',
	templateUrl: './technician-maintenance.component.html',
	styleUrls: ['./technician-maintenance.component.scss']
})
export class TechnicianMaintenanceComponent implements OnInit, OnDestroy {

	kiosks: KioskDto[];
	kiosk: KioskDto;
	info = faInfoCircle;
	view = faSearch;
	qrCode = faQrcode;
	util = UtilService;

	qrValue: string;
	serverError: string;


	constructor(
		private httpService: HttpTechnicianService, 
		private spinner: NgxSpinnerService,
		private toastService: AppToastService) {}

	ngOnInit(): void {
		
	}

	scanAgain() {
		this.qrValue = undefined;
		this.serverError = undefined;
	}

	onCodeResult(resultString: string) {
		this.qrValue = resultString;
		this.sendMaintenanceRequest(this.qrValue);
	}

	private sendMaintenanceRequest(qrValue: string) {
		this.spinner.show();
		this.httpService.doMaintenance(qrValue)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error sending request to perform maintenance', error);
				this.toastService.showDanger({ content: 'Failed to submit request: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			
			this.serverError === undefined;
			
			if (data.responseStatus === false) {
				this.serverError = data.responseStatusMessage;
				this.toastService.showDanger({ content: data.responseStatusMessage });
			}
			this.spinner.hide();
		});

	}


	ngOnDestroy(): void {
		
	}
}